type Api = { channel: string; host: string };

export const API_HOST_CONFIGS: { [host: string]: Api } = {
    'localhost:3006': {
        channel: 'FORMIFY',
        host: 'https://app.formifydev.com',
    },
    'localhost:3007': {
        channel: 'tenant1',
        host: 'https://app.optimat-cpq.com',
    },
    'www.formifydev.com': {
        channel: 'FORMIFY',
        host: 'https://app.formifydev.com',
    },
    'www.formifyrc.com': {
        channel: 'FORMIFY',
        host: 'https://app.formifyrc.com',
    },
    'www.formify.com': {
        channel: 'FORMIFY',
        host: 'https://app.formify.com',
    },
    'demo.formify.com': {
        channel: 'FORMIFY',
        host: 'https://app.formify.com',
    },
    'dev.optimat-cpq.com': {
        channel: 'tenant1',
        host: 'https://app.dev.optimat-cpq.com',
    },
    'optimat-cpq.com': {
        channel: 'tenant1',
        host: 'https://app.optimat-cpq.com',
    },
    'www.demo2.formify.com': {
        channel: 'FORMIFY',
        host: 'https://app.demo2.formify.com',
    },
};
