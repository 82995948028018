const doorHandleProducts = ['door_handle_chrome', 'door_handle_gold', 'door_handle_wood'] as const;
const availableShelveProducts = ['shelve', 'glass_shelve'] as const;

const kitchenProducts = [
    'kitchen_room',
    'simple_room',
    'kitchen_cabinet',
    'kitchen_countertop',
    'kitchen_door_wood',
    'kitchen__embedded_fridge',
    'kitchen_fridge',
    'kitchen_window',
    'kitchen_shelf',
    'kitchen_cover_bar',
    'room_without_walls',
    'room_wall',
] as const;

const availableWardrobeProducts = [
    'wardrobe',
    'wardrobe_free_standing',
    'l_shaped_wardrobe_left',
    'l_shaped_wardrobe_right',
    'u_shaped_wardrobe',
] as const;

export const availableProductCode = [
    'door',
    'kitchen_island',
    'door_glass',
    'double_drawer',
    'quad_drawer',
    'hanger',
    'hanger_front',
    'single_closet',
    'double_closet',
    'led',
    'wrapper',
    'wardrobe_wall',
    'spacing',
    'unknown',
    ...availableWardrobeProducts,
    ...availableShelveProducts,
    ...doorHandleProducts,
    ...kitchenProducts,
] as const;

export type ProductCode = (typeof availableProductCode)[number];

type WardrobeCode = (typeof availableWardrobeProducts)[number];

export function isProductCodes(value: unknown): value is ProductCode {
    return typeof value === 'string' && availableProductCode.includes(value as ProductCode);
}

export function isWardrobeCode(value: unknown): value is WardrobeCode {
    return typeof value === 'string' && availableWardrobeProducts.includes(value as WardrobeCode);
}
