import { IAuth } from '../auth/authServiceFactory';
import { getApiByCurrentHost, getPublicEnv } from '../env/env';
import { isValidationError, SymfonyValidationError } from './symfonyValidationError';

export const fetchUnAuthorizationApi = async (input: RequestInfo, init: RequestInit): Promise<unknown> => {
    init.headers = decorateHeaderWithChannel(init.headers);

    const response = await fetch(input, init);

    if (response.status >= 300 || response.status < 200) {
        const text = await response.text();

        throw new Error(`[${init.method || 'GET'}] ${typeof input === 'string' ? input : input.url}\nRESULT:${text}`);
    }

    return response.json();
};

export class FetchService {
    private auth: IAuth;

    constructor(auth: IAuth) {
        this.auth = auth;
    }

    async post(iri: string, body: { [key: string]: any }) {
        const apiDomainUrl = await getPublicEnv('NEXT_PUBLIC_API_DOMAIN');

        const responseData = await this.request(`${apiDomainUrl}${iri}`, {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        return responseData;
    }

    async patch(iri: string, body: { [key: string]: any }) {
        const apiDomainUrl = await getPublicEnv('NEXT_PUBLIC_API_DOMAIN');

        const responseData = await this.request(`${apiDomainUrl}${iri}`, {
            method: 'PATCH',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/merge-patch+json',
            },
            body: JSON.stringify(body),
        });

        return responseData;
    }

    async put(iri: string, body: { [key: string]: any }) {
        const apiDomainUrl = await getPublicEnv('NEXT_PUBLIC_API_DOMAIN');

        const responseData = await this.request(`${apiDomainUrl}${iri}`, {
            method: 'PUT',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        return responseData;
    }
    async delete(iri: string) {
        const apiDomainUrl = await getPublicEnv('NEXT_PUBLIC_API_DOMAIN');

        const responseData = await this.request(`${apiDomainUrl}${iri}`, {
            method: 'DELETE',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        return responseData;
    }

    async get(iri: string) {
        const apiDomainUrl = await getPublicEnv('NEXT_PUBLIC_API_DOMAIN');

        const responseData = await this.request(`${apiDomainUrl}${iri}`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        return responseData;
    }

    async request(input: RequestInfo, init: RequestInit): Promise<unknown> {
        const token = await this.auth.getSyliusToken();

        init.headers = decorateHeaderWithChannel(init.headers);

        const response = await fetch(
            input,
            token === null
                ? init
                : {
                      ...init,
                      headers: {
                          ...init?.headers,
                          Authorization: `Bearer ${token}`,
                      },
                  },
        );

        if (response.status >= 300 || response.status < 200) {
            const responseTxt = await response.text();

            if (isJsonString(responseTxt)) {
                const parsedJson = JSON.parse(responseTxt);

                if (isValidationError(parsedJson)) {
                    throw new SymfonyValidationError(parsedJson);
                }
            }

            throw new Error(responseTxt);
        }

        return response.status === 204 ? '' : response.json();
    }
}

const decorateHeaderWithChannel = (headers: HeadersInit | undefined) => {
    const { channel } = getApiByCurrentHost();

    if (!headers) {
        return {
            'x-channel': channel,
        };
    }

    return {
        ...headers,
        'x-channel': channel,
    };
};

function isJsonString(str: string): boolean {
    try {
        JSON.parse(str);

        return true;
    } catch (e) {
        return false;
    }
}
