import { TranslationFunction } from '../../components/Translations/LanguageProvider';

export class SymfonyValidationError extends Error {
    readonly title: string;
    readonly detail: string;
    readonly violations: SingleViolation[];

    constructor(error: ValidationError) {
        super(error.detail);
        this.title = error.title;
        this.detail = error.detail;
        this.violations = error.violations;
    }
}

interface ValidationError {
    type: string;
    title: string;
    detail: string;
    violations: SingleViolation[];
}

interface SingleViolation {
    propertyPath: string;
    title: string;
    parameters: Record<string, string>;
    messageTemplate: string;
    validator: string;
}

function isSingleViolation(obj: any): obj is SingleViolation {
    return (
        typeof obj === 'object' &&
        obj !== null &&
        typeof obj.propertyPath === 'string' &&
        typeof obj.title === 'string' &&
        typeof obj.parameters === 'object' &&
        obj.parameters !== null &&
        typeof obj.messageTemplate === 'string' &&
        typeof obj.validator === 'string'
    );
}

export function isValidationError(obj: any): obj is ValidationError {
    return (
        typeof obj === 'object' &&
        obj !== null &&
        typeof obj.type === 'string' &&
        typeof obj.title === 'string' &&
        typeof obj.detail === 'string' &&
        Array.isArray(obj.violations) &&
        obj.violations.every(isSingleViolation)
    );
}

export function translateViolation(fn: TranslationFunction, violation: SingleViolation): string {
    const regex = /({{|%)\s*(\w+)\s*(}}|%)/g;
    const transformedStr = violation.messageTemplate.replace(regex, '${$2}');

    const transformedParameters = Object.fromEntries(
        Object.entries(violation.parameters).map(([key, value]) => [key.replace(regex, '$2'), value]),
    );

    return fn(`validation.${violation.validator}`, transformedStr, transformedParameters);
}
